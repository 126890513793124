import React from "react";
import AuthStore from "./AuthStore";
import KeywordStore from "./KeywordStore";

class RootStore {

    public authStore;  public keywordStore;   

    constructor(){

        this.authStore = new AuthStore();
        this.keywordStore = new KeywordStore();
        
    }
}

export const StoreContext = React.createContext(new RootStore());

export const useStores = () => React.useContext(StoreContext);