import BaseFooter from "./BaseFooter"
import { DEFAULT_THEME } from '../../Config';

type Props = {
	children: JSX.Element
};

const BaseLayout = ({ children }:Props) => {

	return <div className="wrapper wrapper--form">
            <div className="form-container">
			<div className="form-container__inner">
				<div className="form__logo">
					<a href="#" className="logo">
						<svg className="logo-main">
							<use xlinkHref={ DEFAULT_THEME.baseLayoutLogo }  />
						</svg>
					</a>
				</div>
                
                { children }
				{/* <Outlet /> */}

			</div>

			<BaseFooter />
		</div>

        </div>
}

export default BaseLayout;