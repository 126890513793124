import { useEffect, useState } from "react";

const useAuth = (authStore: any) => {
  const [isAuth, setIsAuth] = useState({
    auth: false,
    loading: true,
  });

  useEffect(() => {
    const handleVerifyLogin = () => {
      try {
        //await authStore.verifyLogin();

        const user = authStore.getUser();

        if (user.token) {
          setIsAuth({
            auth: true,
            loading: false,
          });
        } else {
          setIsAuth({
            auth: false,
            loading: false,
          });
        }
      } catch (error) {
        console.log("use auth", error);
      }
    };

    handleVerifyLogin();
  }, [authStore]);

  return { isAuth };
};

export { useAuth };

export const useBodyClass = (klassName: string) => {
  useEffect(() => {
    document.body.className = klassName;
  }, [klassName]);

  return () => {
    document.body.className = "";
  };
};
