
import { Link } from "react-router-dom";

function NoMatch() {
  return (
    <div className='from-access'>

      <h5 className='form__title'>Nothing to see here!</h5>
      <p className="text-center">
        <Link to="/">Go to the login page</Link>
      </p>

    </div>
  )
}

export default NoMatch