import React, { useState, useMemo } from "react";
import { Table } from "../../Components";
import { TableColumns } from "./ParticipantCol";
import { CSVLink } from "react-csv";
import { useStores } from "../../stores";

const FilteredParticpants = ({ winnersData }: { winnersData: any[] }) => {
  const [filterTable, setFilterTable] = useState("");
  const columns = TableColumns();
  const profile = useStores().authStore.user.profile;

  const reportData = useMemo(() => {
    if (winnersData) {
      return winnersData.map((rp) => {
        return {
          "First Name": rp.firstName,
          "Last Name": rp.lastName,
          "Email Address": rp.email,
          "Phone Number": rp.phone,
          City: rp.city,
          Province: rp.province,
          "Prize Won": rp.prize,
          "Prize Win Time": rp.prizeWinTime,
          Keyword: rp.keyword,
          WinnerType: rp.winnerType,
          "Expiration date": rp.expirationDate || "N/A",
          "Claim Status": rp.status,
          "Claim first name": rp.declaration?.firstName || "N/A",
          "Claim last name": rp.declaration?.lastName || "N/A",
          "Claim Email address": rp.declartion?.email || "N/A",
          "Claim Address line1": rp.declartion?.address || "N/A",
          "Claim Address line2": rp.declartion?.address2 || "N/A",
          "Claim City": rp.declartion?.city || "N/A",
          "Claim Province": rp.declartion?.province || "N/A",
          "Claim Postal Code": rp.declartion?.postal || "N/A",
          "Claim Phone Number": rp.declartion?.phone || "N/A",
          Stores: rp.stores,
        };
      });
    }
  }, [winnersData]);

  const standardReport = useMemo(() => {
    if (winnersData) {
      return winnersData.map((rp) => {
        return {
          creationTime: rp.creationTime,
          "First Name": rp.firstName,
          Keyword: rp.keyword,
          Stores: rp.stores,
        };
      });
    }
  }, [winnersData]);

  const hideColumns = profile.toLowerCase() === "standard" ? ["email", "lastName"] : [];

  return winnersData.length > 0 ? (
    <>
      <div className="main__head">
        <h2 className="main__title">Participants</h2>
      </div>

      <div className="main__body">
        <div className="tabs js-tabs">
          <div className="search search--alt search--medium main__search">
            <div className="search__row">
              <label htmlFor="q" className="hidden">
                Search
              </label>
              <input
                type="search"
                name="q"
                id="q"
                value={filterTable}
                placeholder="Search"
                className="search__field"
                onChange={(e) => setFilterTable(e.target.value)}></input>
              <button type="submit" className="search__btn">
                <svg className="ico-search">
                  <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                </svg>
              </button>
            </div>

            <CSVLink
              filename={`Campaign-full-Report-${new Date().toLocaleDateString()}`}
              className="btn btn--medium btn--mobile-small"
              style={{ marginLeft: "2rem" }}
              data={profile.toLowerCase() === "standard" ? standardReport ?? [] : reportData ?? []}
              asyncOnClick={true}
              target="_blank">
              Download Full Report
            </CSVLink>
          </div>
        </div>
      </div>
      <div className="tabs__body mt-5">
        <div className="table table--alt table--tabs table--big">
          <Table
            columns={columns}
            data={winnersData ? winnersData : []}
            tablePageSize={15}
            filterValue={filterTable}
            hideColumns={hideColumns}
          />
        </div>
      </div>
    </>
  ) : (
    <p>Currently there are no records to display.</p>
  );
};

export default FilteredParticpants;
