import {   
    useMatch,
    useNavigate,
    useResolvedPath
  } from "react-router-dom";
import type { LinkProps } from "react-router-dom";

function CustomLink({ children, to, ...props }: LinkProps ) {

  const navigate = useNavigate();

  const resolved = useResolvedPath(to);

  const match = useMatch({ path: resolved.pathname, end: true });

  return <>
      <li className={ match ? 'is-current' : ''  }>
      <a href="#" {...props} onClick={ e=>{e.preventDefault(); navigate(to, {replace: true}) }}>
            {children}
        </a>
    </li>
          
         
    </>;
}

export default CustomLink;
