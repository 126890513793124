import FooterCopyright from "./FooterCopyright";

function BaseFooter() {
  return (
    <div className="copyright-fixed-bottom">
		<FooterCopyright />
	</div>
  )
}

export default BaseFooter