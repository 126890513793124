import React, { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import "./index.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Chart from "./chart";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { campaignClient } from "../../api";
import { PageLoader } from "../../Components";
import { provinces } from "../../helpers/utils";
import dayjs from "dayjs";
import axios from "axios";


import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

type DateSelectionForm = {
  dateRange: string;
  startDate: Date;
  endDate: Date;
};

const Overall = () => {
  const {
    reset,
    handleSubmit,
    getValues,
    register,
    watch,
    control,
    formState: { errors },
  } = useForm<DateSelectionForm>({
    mode: "onChange",
    defaultValues: {
      dateRange: "custom",
      startDate: new Date(dayjs().subtract(1, "month").toISOString()),
      endDate: new Date(),
    },
  });

  const daterangeregister = {
    ...register("dateRange", { required: { value: true, message: "Please select a date range." } }),
  };

  const daterangewatch = watch();

  const { isLoading: loadingKeywords, data: keywords } = useQuery(["getKeywords", {}], async () => {
    const { keywords }: { keywords: MainDbReturnTypes.Keyword[] } = await campaignClient.call(
      "getKeywords",
      {}
    );
    const filtered = keywords.filter((k) => dayjs().diff(dayjs(k.startTime), "years", true) < 2);
    return filtered
  }, { refetchOnWindowFocus: false })

  const keywordsFilterd = useMemo(() => {

    const temp:MainDbReturnTypes.Keyword[] = []

    if (daterangewatch.startDate && daterangewatch.endDate && keywords) {
      const filterStartDate = dayjs(daterangewatch.startDate).startOf('date')
      const filterEndDate = dayjs(daterangewatch.endDate).endOf('date')
      let filtered = keywords?.filter(k => dayjs(k.startTime).startOf('date').isBetween(filterStartDate,filterEndDate,null,'[]'))
      return filtered
    }
    else {
      return temp
    }
  }, [keywords, daterangewatch])


  const { isLoading: loadingParticipants, data } = useQuery(["getallparticipants", keywordsFilterd], async () => {
    let participants: { [key: string]: any[] } = {};

    for (let k of keywordsFilterd) {

   

      let res: any = await campaignClient.call(
        "getGrandPrizeEntries",
        {
          prizePoolKey: k.basicSettings.primaryPrizePoolKey
        }
      );
      let keywordusers
      if (res.grandPrizeEntries) {
        keywordusers = res.grandPrizeEntries;
      }
      if (res.result) {
        keywordusers = res.result.grandPrizeEntries;
      }
      if (res.largeResultUrl) {
        const result = await axios(res.largeResultUrl);

        const response: MainDbReturnTypes.GrandPrizeEntry[] = await result.data.result.grandPrizeEntries;
        keywordusers = response;
      }
      participants[k.keywordKey] = keywordusers
    }

    return participants;
  }, { refetchOnWindowFocus: false });


  const regionwiseParticipants = useMemo(() => {
    if (data) {
      let participants = [];
      for (let key in data) {
        participants.push(...data[key]);
      }
      let regionwise: { [key: string]: any[] } = {};
      for (let p of participants) {
        const province_user = p.participant.province
        if (regionwise[provinces[province_user]]) {
          if (
            dayjs(p.creationTime).isBetween(
              dayjs(watch("startDate")),
              dayjs(watch("endDate")),
              "date"
            )
          ) {
            regionwise[provinces[province_user]].push(p);
          }
        } else {
          regionwise[provinces[province_user]] = [p];
        }
      }
      const counts: { [key: string]: number } = {};
      Object.keys(regionwise).map((k) => (counts[k] = regionwise[k].length));

      const keyValueArray = Object.entries(counts);
      keyValueArray.sort((a, b) => b[1] - a[1]);
      const sortedObject = Object.fromEntries(keyValueArray);
      return sortedObject;
    }
  }, [data, daterangewatch]);

  const contestwiseParticipants = useMemo(() => {
    if (data) {
      let participants: { [key: string]: number } = {};
      for (let key in data) {
        let filteredParticipants = data[key].filter((p) =>
          dayjs(p.creationTime).isBetween(
            dayjs(watch("startDate")).startOf('date'),
            dayjs(watch("endDate")).endOf('date'),
          null,"[]")
        );
        participants[key] = filteredParticipants.length;
      }

      const keyValueArray = Object.entries(participants);
      keyValueArray.sort((a, b) => b[1] - a[1]);
      const sortedObject = Object.fromEntries(keyValueArray);
      let removeZeros: { [key: string]: number } = {};
      for (let key in sortedObject) {
        const keyword = keywordsFilterd.find(k=>k.keywordKey === key)
          removeZeros[keyword!.word] = sortedObject[key];
      }
      return removeZeros;
    }
  }, [data, daterangewatch]);

  const [filter, setFilter] = useState<DateSelectionForm>();

  const handleFilter = async (dates: DateSelectionForm) => {
    setFilter(dates);
  };

  const totalParticipation = contestwiseParticipants
    ? Object?.values(contestwiseParticipants).reduce((a, b) => a + b, 0)
    : 0;
  const totalContests = contestwiseParticipants ? Object.keys(contestwiseParticipants).length : 0;
  

  return (
    <div className="main__head overall">
   
      <h2 className="main__title">Overall contest overview</h2>
      <div className="date-selection">
        <form onSubmit={handleSubmit(handleFilter)}>
          <div className="filter">
            <div className="form-group">
              <label style={{ fontWeight: "bold", marginBottom: "2px" }}>Select Date Range</label>
              <select
                className="date__control"
                {...daterangeregister}
                onChange={async (e) => {
                  daterangeregister.onChange(e);
                  switch (e.currentTarget.value) {
                    case "1":
                      reset({
                        dateRange: "1",
                        startDate: new Date(dayjs().subtract(1, "month").toISOString()),
                        endDate: new Date(),
                      });
                      break;
                    case "2":
                      reset({
                        dateRange: "2",
                        startDate: new Date(dayjs().subtract(2, "month").toISOString()),
                        endDate: new Date(),
                      });
                      break;
                    case "3":
                      reset({
                        dateRange: "3",
                        startDate: new Date(dayjs().subtract(3, "month").toISOString()),
                        endDate: new Date(),
                      });
                      break;

                    case "4":
                      reset({
                        dateRange: "4",
                        startDate: new Date(dayjs().subtract(6, "month").toISOString()),
                        endDate: new Date(),
                      });
                      break;

                    case "5":
                      reset({
                        dateRange: "5",
                        startDate: new Date(dayjs().subtract(9, "month").toISOString()),
                        endDate: new Date(),
                      });
                      break;

                    case "6":
                      reset({
                        dateRange: "6",
                        startDate: new Date(dayjs().subtract(12, "month").toISOString()),
                        endDate: new Date(),
                      });
                      break;
                    default: {
                      reset({
                        dateRange: "custom",
                        startDate: new Date(dayjs().subtract(1, "month").toISOString()),
                        endDate: new Date(),
                      });
                    }
                  }
                }}>
                <option value="custom">Custom</option>
                <option value="1">Last month</option>
                <option value="2">2 months</option>
                <option value="3">3 months</option>
                <option value="4">6 months</option>
                <option value="5">9 months</option>
                <option value="6">1 year</option>
              </select>
            </div>
            <div className="form-group">
              <label style={{ fontWeight: "bold", marginBottom: "2px" }}> Start Date</label>
              <Controller
                control={control}
                name="startDate"
                render={({ field }) => (
                  <DatePicker
                    placeholderText="Start date"
                    onChange={(date: Date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="MMM d, yyyy"
                    className="date__control"
                    disabled={watch("dateRange") === "custom" ? false : true}
                  />
                )}
              />
            </div>
            <div className="form-group">

              <label style={{ fontWeight: "bold", marginBottom: "2px" }}> End date</label>
              <Controller
                control={control}
                name="endDate"
                render={({ field }) => (
                  <DatePicker
                    placeholderText="End date"
                    onChange={(date: Date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="MMM d, yyyy"
                    className="date__control"
                    disabled={watch("dateRange") === "custom" ? false : true}
                    minDate={new Date(watch('startDate').toISOString())}
                  />
                )}
                rules={{
                  validate: () => {
                    return getValues("startDate") < getValues("endDate");
                  },
                }}
              />
            </div>
          </div>
          {errors.endDate && (
            <p className="error-message">
              <i className="fas fa-exclamation-circle" /> Invalid Date Range.
            </p>
          )}
        </form>
      </div>
      {(loadingKeywords || loadingParticipants) ? <PageLoader/>:<>
      <div className="boxes-info">
        <ul>
          <li>
            <div className="info-box">
              <p>Total participation</p>
              <h1>{totalParticipation}</h1>
            </div>
          </li>
          <li>
            <div className="info-box">
              <p>Total contests</p>
              <h1>{totalContests}</h1>
            </div>
          </li>
        </ul>
      </div>
      <div className="chart">
        <h2>
          <strong> Contest participation by region</strong>
        </h2>
        {regionwiseParticipants && (
          <Chart participantsData={regionwiseParticipants} legend={false} />
        )}
      </div>
      <div className="chart">
        <h2>
          <strong> Contest-wide participation</strong>
        </h2>
        {contestwiseParticipants && (
          <Chart participantsData={contestwiseParticipants} legend={true} />
        )}
      </div>
      </>}
    </div>

  );
};

export default Overall;
