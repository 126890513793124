import { useState } from 'react';

type Props =  {
    handleNav: React.Dispatch<React.SetStateAction<boolean>>,
    mobileNav: boolean,   
    handleLogOut: ()=>{}
}
   
function SideBarHeader({ mobileNav, handleNav, handleLogOut }: Props) {

    const [toggle, setToggle] = useState(false);

  return <div className="sidebar__head">
  <a href="/" className="burger js-burger sidebar__burger" onClick={ e=>{ e.preventDefault(); handleNav(!mobileNav) }}>
      <span></span>

      <span></span>

      <span></span>
  </a>

  <a href="/" className="sidebar__logo logo logo--alt">
      <svg className="logo-main">
      <use xlinkHref="/assets/images/svg/sprite.svg#logo-main"></use>
      </svg>
  </a>

  <div className={ ` account-menu account-menu--mobile sidebar__actions ${toggle ? 'is-active' : ''}` }>
      <a href="/" className="account-menu__trigger js-account-menu-trigger" onClick={ e=> { e.preventDefault(); setToggle(!toggle)}}>
          <svg className="ico-account">
              <use xlinkHref="/assets/images/svg/sprite.svg#ico-account"></use>
          </svg>
      </a>

      <ul className="account-menu__dropdown">
          <li><a href="/" onClick={ e=> { e.preventDefault(); handleLogOut(); }}>Logout</a></li>
      </ul>
  </div>
</div>;
}

export default SideBarHeader;
