import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 100, // <----- This prop sets the stepSize
      },
    },
  },
  plugins: {
    legend: {
      display:false
    },
    title: {
      display: false,
      text: "Participants by month",
    },
  },
};

export default function Graph(graphLabels: {
  graphLabels: { x: string[]; y: number[] };
  selectedmonths: number;
}) {
  const data = {
    labels: graphLabels.graphLabels.x,
    scales:{
   
      y: {
        grid: {
          display: true
        }
      }
    },
    datasets: [
      {
        label: "Number of Participants",
        data: graphLabels.graphLabels.y,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "blue",
      },
    ],
  };
  return <Line options={options} data={data} />;
}
