import { Button, Spinner } from "react-bootstrap";
import { editUser } from "../../api";
import { useMutation, useQueryClient } from "react-query";
import { showToast } from "../../Components/Toast/ToastManager";
import { useStores } from "../../stores";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { validKeywords } from "../../helpers/utils";

type AppProps = {
  showSidebar: boolean;
  closeHandler: (val: boolean) => void;
  agency?: any;
  addUsertoAdmin: Function;
  refetch: any;
  userEmail: string;
  userRole: string;
  userFirstName: string;
  userLastName: string;
  userCustomAttributeName: string;
  userCustomAttributeValue: string;
};

type EditFormData = {
  email: string;
  lastName: string;
  firstName: string;
  role: string;
  customAttributeName: string;
  customAttributeValue: string;
};

const EditAdmin = ({
  showSidebar,
  closeHandler,
  refetch,
  userEmail,
  userRole,
  userFirstName,
  userLastName,
  userCustomAttributeName,
  userCustomAttributeValue,
}: AppProps) => {
  const { authStore } = useStores();
  const {
    register,
    handleSubmit,
    formState: { dirtyFields },
    getValues,
    setValue,
    resetField,
  } = useForm<EditFormData>({ mode: "all" });
  const queryClient = useQueryClient();
  const { mutate: editAdminUser, isLoading: isLoadingEdit } = useMutation(
    editUser,
    {
      onSuccess: (data) => {
        refetch();
        closeHandler(false);
        showToast({
          content: "User has been updated successfully.",
          duration: 3000,
          error: false,
        });
      },
      onError: () => {
        showToast({
          content: "User already exists.",
          duration: 3000,
          error: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries("getUserList");
      },
    }
  );

  useEffect(() => {
    setValue("firstName", userFirstName);
    setValue("lastName", userLastName);
    setValue("role", userRole);
    setValue("customAttributeName", userCustomAttributeName);
    setValue("customAttributeValue", userCustomAttributeValue);
  }, [
    userFirstName,
    userLastName,
    userRole,
    userCustomAttributeName,
    userCustomAttributeValue,
    setValue,
  ]);

  const handleEdit = (values: any) => {
    values.token = authStore.user.token;
    values.email = userEmail;
    values.firstName = dirtyFields.firstName
      ? getValues("firstName")
      : userFirstName;
    values.lastName = dirtyFields.lastName
      ? getValues("lastName")
      : userLastName;
    values.role = dirtyFields.role ? getValues("role") : userRole;
    values.customAttributeName = dirtyFields.customAttributeName
      ? getValues("customAttributeName")
      : userCustomAttributeName;
    values.customAttributeValue = dirtyFields.customAttributeValue
      ? getValues("customAttributeValue")
      : userCustomAttributeValue;
    editAdminUser(values);
    // resetField("firstName");
    // resetField("lastName");
    // resetField("role");
    resetField("customAttributeName");
    resetField("customAttributeValue");
  };

  return (
    <nav id="nav" className={showSidebar ? "show" : ""}>
      <div className="sidebarContent">
        <h2>Edit User</h2>
        <a
          href="#"
          className="closeSideBar"
          onClick={(e) => {
            e.preventDefault();
            closeHandler(false);
            setValue("firstName", userFirstName);
            setValue("lastName", userLastName);
            setValue("role", userRole);
          }}
        >
          <i className="fas fa-times"></i>
        </a>
        <form onSubmit={handleSubmit(handleEdit)}>
          <div className="formContent">
            <div className="form-group">
              <label htmlFor="agency">First name</label>
              <input
                type="text"
                className="form-control"
                defaultValue={userFirstName}
                {...register("firstName")}
              />
            </div>
            <div className="form-group">
              <label htmlFor="agency">Last name</label>
              <input
                type="text"
                className="form-control"
                defaultValue={userLastName}
                {...register("lastName")}
              />
            </div>
            <div className="form-group">
              <label htmlFor="agency">Email *</label>
              <input
                type="text"
                className="form-control"
                {...register("email")}
                value={userEmail}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="agency">Role</label>
              <input
                type="text"
                className="form-control"
                defaultValue={userRole}
                {...register("role")}
              />
            </div>

            <div className="form-group">
              <label htmlFor="agency">Additional attribute name</label>
              <select
                className="form-control"
                {...register("customAttributeName")}
              >
                <option value="">Additional attribute name</option>
                {validKeywords.map((keyword) => (
                  <option key={keyword} value={keyword}>
                    {keyword}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="agency">Additional attribute value</label>
              <input
                placeholder="Additional attribute value"
                type="text"
                className="form-control"
                defaultValue={userCustomAttributeValue}
                {...register("customAttributeValue")}
              />
            </div>

            <div className="actionBtns">
              {isLoadingEdit ? (
                <Button variant="primary" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <button
                  type="submit"
                  className="btn"
                  disabled={Object.keys(dirtyFields).length > 0 ? false : true}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </nav>
  );
};

export default EditAdmin;
