import { useState } from "react";
import { Row, TableProps } from "react-table";
import { useStores } from "../../stores";

export const TableColumns = (fns: {
  resendClaimEmail: Function;
  loading: boolean;
  setSelectedWinner: Function;
}) => {
  const profile = useStores().authStore.user.profile;
  const [currentindex, setCurrentIndex] = useState<number>();
  const COLUMNS = [
    {
      Header: "No.",
      accessor: "colNumber",
      width: "3.6%",
      Cell: (TableInfo: TableProps) => {
        const nmb = TableInfo.row.index + 1;
        return nmb;
      },
    },
    {
      Header: "First Name",
      accessor: "firstName",
      width: "7.7%",
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      width: "7.7%",
    },
    {
      Header: "Email Address",
      accessor: "email",
      width: "13.1%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].email ? (
          profile === "shipping" ? (
            <p>{TableInfo.data[TableInfo.row.index].email}</p>
          ) : (
            <a
              className="link-more link-more--alt"
              href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
              {TableInfo.data[TableInfo.row.index].email}
            </a>
          )
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Phone Number",
      accessor: "phone",
      width: "5.1%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].phone
          ? TableInfo.data[TableInfo.row.index].phone
          : "N/A";
      },
    },
    {
      Header: "Prize won",
      accessor: "prize",
      width: "5.1%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].prize
          ? TableInfo.data[TableInfo.row.index].prize
          : "N/A";
      },
    },
    {
      Header: "Prize claimed time",
      accessor: "prizeClaimTime",
      width: "5.1%",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (rowA.values[columnId] > rowB.values[columnId]) return 1;
        if (rowB.values[columnId] > rowA.values[columnId]) return -1;
        return 0;
      },
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].prizeClaimTime
          ? TableInfo.data[TableInfo.row.index].prizeClaimTime.toLocaleString()
          : "N/A";
      },
    },
    {
      Header: "Keyword",
      accessor: "keyword",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Release form",
      accessor: "releaseForm",
      Cell: (TableInfo: TableProps) => {
        let winnerKey = TableInfo.data[TableInfo.row.index].prizeWinnerKey;
        let claimed= TableInfo.data[TableInfo.row.index].status === "Claimed"
        return claimed ? <button  className="link-btn link-more link-more--alt "
        style={{
          background: "transparent",
          border: "none",
          textDecoration: "underline",
        }} onClick={() => fns.setSelectedWinner(winnerKey)}>Release Form</button>:<p>Pending..</p>
      },
    },
    {
      Header: "Winner type",
      accessor: "winnerType",
    },
    {
      Header: "Expiration date",
      accessor: "expirationDate",
    },
    {
      Header: "Stores",
      accessor: "stores",
    },
    {
      Header: "Action",
      accessor: "action",
      show: false,
      Cell: (TableInfo: TableProps) => {
        let prizeWinnerKey = TableInfo.data[TableInfo.row.index].prizeWinnerKey;
        let status = TableInfo.data[TableInfo.row.index].status;
        let sessionKey = TableInfo.data[TableInfo.row.index].sessionKey;
        if (status === "Expired") {
          return <p></p>;
        } else if (fns.loading && currentindex === TableInfo.row.index) {
          return <p>Sending...</p>;
        } else {
          return (
            <button
              className="link-btn link-more link-more--alt "
              style={{
                background: "transparent",
                border: "none",
                textDecoration: "underline",
              }}
              onClick={() => {
                setCurrentIndex(TableInfo.row.index);
                fns.resendClaimEmail(prizeWinnerKey, sessionKey);
              }}>
              Resend Prize email
            </button>
          );
        }
      },
    },
    {
      Header: "Fulfilment partner",
      accessor: "fulfilmentPartner",
    },
    {
      Header: "Submitted to fulfillment",
      accessor: "submittedToFullfillment",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].submittedToFullfillment ? "Yes" : "No";
      },
    },
  ];
  return COLUMNS;
};
