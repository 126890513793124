import { useNavigate } from "react-router";
import { Row, TableProps } from "react-table";
import { useStores } from "../../stores";

export const TableColumns = () => {
  const navigate = useNavigate();
  const profile = useStores().authStore.user.profile;
  const COLUMNS = [
    {
      Header: "No.",
      accessor: "colNumber",
      width: "1%",
      Cell: (TableInfo: TableProps) => {
        const nmb = TableInfo.row.index + 1;
        return nmb;
      },
    },
    {
      Header: "Creation Time",
      sortType: "datetime",
      accessor: "creationTime",
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].creationTime.toLocaleString();
        return dateString;
      },
    },

    {
      Header: "First Name",
      accessor: "firstName",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].firstName ? (
          profile === "admin" || profile ==="cmservice" ? (
            <button
              className="link-more link-more--alt link-btn"
              style={{ border: "none", background: "transparent" }}
              onClick={() => {
                navigate(`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`);
              }}>
              {TableInfo.data[TableInfo.row.index].firstName}
            </button>
          ) : (
            TableInfo.data[TableInfo.row.index].firstName
          )
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].lastName ? (
          <button
            className="link-more link-more--alt link-btn"
            style={{ border: "none", background: "transparent" }}
            onClick={() => {
              navigate(`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`);
            }}>
            {TableInfo.data[TableInfo.row.index].lastName}
          </button>
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Email Address",
      accessor: "email",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].email ? (
          <button
            className="link-more link-more--alt link-btn"
            style={{ border: "none", background: "transparent" }}
            onClick={() => {
              navigate(`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`);
            }}>
            {TableInfo.data[TableInfo.row.index].email}
          </button>
        ) : (
          "N/A"
        );
      },
    },
    // {
    //   Header: "Address line1",
    //   accessor: "address1",

    // },
    // {
    //   Header: "Address line2",
    //   accessor: "address2",

    // },
    // {
    //   Header: "City",
    //   accessor: "city",

    // },
    // {
    //   Header: "Province",
    //   accessor: "province",

    // },
    // {
    //   Header: "Postal code",
    //   accessor: "postal",

    // },{
    //   Header: "Phone Number",
    //   accessor: "phone",

    // },
    {
      Header: "Stores",
      accessor: "stores",
    },

    {
      Header: "Keyword",
      accessor: "keyword",
    },
  ];
  return COLUMNS;
};
