import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);



export const options= {
  indexAxis: "y" as const,
  clip:0,
  scales: {
    y: {
      grid: {
        display: false,
      },
    },
    x:{
      ticks:{
        padding:5
      }
    }
  },
  elements: {
    bar: {
      borderWidth: 2,
    },
    
  },
  responsive: true,
  // plugins:plugin1
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    datalabels: {
      anchor: `end` as "end",
      align: `end` as "end",
      formatter: function (value: any, context: any) {
        return context.chart.formattedData[context.dataIndex];
      },
    },
  },
};

export const Chart = ({
  participantsData,
  legend,
}: {
  participantsData: { [key: string]: number };
  legend?: boolean;
}) => {
  let labels = Object.keys(participantsData);
  let values = Object.values(participantsData);

  let backgrounds = values.map((v, i) => {
    if (i < 3) {
      return "#30DB56";
    } else if (i > values.length - 4) {
      return "#E51A40";
    } else {
      return "#A4ACB7";
    }
  });
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Most popular",
        data: values,
        backgroundColor: legend ? backgrounds : "blue",
        barThickness: 50,
        catagoryPercentage: 0.2,
        maxBarThickness: 12,
        datalabels: {
          color: "blue",
          clip:false,
          align: `end` as "end",
          padding:{
            right:-15,
            
          },
          formatter: function (value: any, context: any) {
            return values[context.dataIndex];
          },
        },
      },
    ],
  };

  return (
    <>
      <div className="graph-box">
        {legend && (
          <div className="legend-box">
            <div className="legend">
              <div className="check_box check_box_1"></div>Most popular programs
            </div>
            <div className="legend">
              <div className="check_box check_box_2"></div>Least popular programs
            </div>
            <div className="legend">
              <div className="check_box check_box_3"></div>Remaining programs
            </div>
          </div>
        )}

        <Bar options={options} data={data} plugins={[ChartDataLabels]}/>
      </div>
    </>
  );
};

export default Chart;
