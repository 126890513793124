import { makeAutoObservable, observable, action } from "mobx";

class KeywordStore {
  keyword = {
    word: "",
    keywordKey: "",
    shipping: { email: "", notificationTime: "", partNumber: "", projectNumber: "" },
    contestType:"",
    dynamicSettings:{}
  };

  constructor() {
    makeAutoObservable(this, {
      keyword: observable,
      setKeyword: action,
    });
  }

  setKeyword(item: { word: string; keywordKey: string; shipping: any ;contestType:string,dynamicSettings:any}) {
    this.keyword = { ...item };
  }
}

export default KeywordStore;
