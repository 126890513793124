import { Button, Spinner } from "react-bootstrap";
import { signup } from "../../api";
import { useMutation, useQueryClient } from "react-query";
import { showToast } from "../../Components/Toast/ToastManager";
import { useStores } from "../../stores";
// import { IUser } from "../../interfaces";
import { useForm } from "react-hook-form";
import { emailRegex, validKeywords } from "../../helpers/utils";

export interface IUser {
  firstName?: string;
  lastName?: string;
  email?: string;
  code?: string;
  username?: string;
  password?: string;
  token?: string;
}

type AppProps = {
  showSidebar: boolean;
  closeHandler: (val: boolean) => void;
  agency?: any;
  addUsertoAdmin: Function;
  refetch: any;
  userEmail: string;
  userRole: string;
  userFirstName: string;
  userLastName: string;
  userCustomAttributeName: string;
  userCustomAttributeValue: string;
};

type AddFormData = {
  email: string;
  lastName: string;
  firstName: string;
  role: string;
  customAttributeName: string;
  customAttributeValue: string;
};

const AddAdmin = ({
  showSidebar,
  closeHandler,
  agency,
  refetch,
  userEmail,
  userRole,
  userFirstName,
  userLastName,
  userCustomAttributeName,
  userCustomAttributeValue,
}: AppProps) => {
  const { authStore } = useStores();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    reset,
  } = useForm<AddFormData>();

  const queryClient = useQueryClient();

  const { mutate: addAdminUser, isLoading: isLoadingAdd } = useMutation(
    signup,
    {
      onSuccess: (data) => {
        refetch();
        closeHandler(false);
        showToast({
          content: "User has been added successfully.",
          duration: 3000,
          error: false,
        });
      },
      onError: () => {
        showToast({
          content: "User already exists.",
          duration: 3000,
          error: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries("getUserList");
      },
    }
  );

  const handleAdd = (values: IUser, fns: any) => {
    reset();
    values.token = authStore.user.token;
    values.username = userEmail;
    // console.log(values);
    addAdminUser(values);
  };

  return (
    <nav id="nav" className={showSidebar ? "show" : ""}>
      <div className="sidebarContent">
        <h2>Add User</h2>
        <a
          href="#"
          className="closeSideBar"
          onClick={(e) => {
            e.preventDefault();
            closeHandler(false);
          }}
        >
          <i className="fas fa-times"></i>
        </a>

        <form onSubmit={handleSubmit(handleAdd)}>
          <div className="formContent">
            <div className="form-group">
              <label htmlFor="agency">First name</label>
              <input
                type="text"
                className="form-control"
                {...register("firstName")}
                // defaultValue={userFirstName}
                placeholder="First name"
              />
              {errors.firstName && (
                <div className="error">
                  <>
                    <i className="fas fa-exclamation-circle" />
                    {errors.firstName}
                  </>
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="agency">Last name</label>
              <input
                type="text"
                className="form-control"
                {...register("lastName")}
                // defaultValue={userLastName}
                placeholder="Last name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="agency">Email *</label>
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Please enter your email.",
                  },
                  pattern: {
                    value: emailRegex,
                    message: "Please enter a valid email.",
                  },
                })}
              />
              {errors.email && (
                <p className="error">
                  <i className="fas fa-exclamation-circle" />{" "}
                  {errors.email.message}
                </p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="agency">Role</label>
              <input
                type="text"
                className="form-control"
                {...register("role")}
                // defaultValue={userRole}
                placeholder="Role"
              />
            </div>

            <div className="form-group">
              <label htmlFor="agency">Additional attribute name</label>
              <select
                className="form-control"
                {...register("customAttributeName")}
              >
                <option value="">Additional attribute name</option>
                {validKeywords.map((keyword) => (
                  <option key={keyword} value={keyword}>
                    {keyword}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="agency">Additional attribute value</label>
              <input
                placeholder="Additional attribute value"
                type="text"
                className="form-control"
                defaultValue={userCustomAttributeValue}
                {...register("customAttributeValue")}
              />
            </div>

            <div className="actionBtns">
              {isLoadingAdd ? (
                <Button variant="primary" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <button
                  type="submit"
                  className="btn"
                  disabled={isDirty ? false : true}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </nav>
  );
};

export default AddAdmin;
