import { useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader, Table } from "../../Components";
import { TableColumns } from "./flowCol";
import { campaignClient } from "../../api";
import { byKey } from "../../helpers/utils";
import { useStores } from "../../stores";

type sentEmailResult = {
  campaignKey: string;
  creationTime: Date;
  emailTemplateKey: string;
  fromEmail: string;
  fromName: string;
  lastUpdateTime: Date;
  operationId: string;
  targets: any[];
};

type sentEmailResults = {
  sentEmailResults: sentEmailResult[];
};

interface EmailTemplateHasSubj extends MainDbReturnTypes.EmailTemplate {
  subject: string;
}
type RegisterFormData = {
  firstName: string | null;
  lastName: string;
  email: string;
  title: string | any;
  brokerageName: string | any;
};

function Participant() {
  const params = useParams();

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);

  const [message, setMessage] = useState({ error: false, msg: "" });

  const [emailparams, setemailparams] = useState<any>();

  const profile = useStores().authStore.user.profile;

  const { isLoading: isLoadingParticipant, data: matchedParticipant } = useQuery(
    "getParticipant",
    async () => {
      const res: MainDbReturnTypes.Participants = await campaignClient.call("getParticipants", {
        sessionKey: params.key,
      });

      return res.participants[0];
    }
  );

  const { isLoading: isLoadingWinners, data: prizeWinners } = useQuery(
    "getPrizeWinners",
    async () => {
      const res: MainDbReturnTypes.PrizeWinners = await campaignClient.call("getPrizeWinners", {
        sessionKey: params.key,
      });

      return res.prizeWinners;
    }
  );

  const { isLoading: isLoadingPrizes, data: prizes } = useQuery("getPrizes", async () => {
    const res: MainDbReturnTypes.Prizes = await campaignClient.call("getPrizes", {});

    const prizes = byKey(res.prizes, (x) => x.prizeKey);

    return prizes;
  });

  const { isLoading: isLoadingPools, data: pools } = useQuery("getPrizePools", async () => {
    const res: MainDbReturnTypes.PrizePools = await campaignClient.call("getPrizePools", {});

    return res.prizePools;
  });

  const winners = useMemo(() => {
    if (prizeWinners && prizes && pools) {
      return prizeWinners.map((v) => {
        return {
          prize: prizes[v.prizeKey] ? prizes[v.prizeKey][0].prizeName : "",
          status: v.declarationAndRelease ? "Claimed" : "Unclaimed",
          poolName: pools[0].prizePoolName,
          winningTime: new Date(v.winningTimestamp!).toLocaleString(),
          prizeWinnerKey: v.prizeWinnerKey,
        };
      });
    }
  }, [prizeWinners, prizes, pools]);

  const resendemail = async () => {
    const emailResults: any = await campaignClient.call("sendEmail", emailparams);

    if (emailResults && emailResults[0].accepted) {
      setMessage({ ...message, msg: "Email re-sent successfully." });
    } else {
      setMessage({ error: true, msg: "Email re-sent failed." });
    }

    setTimeout(() => {
      setMessage({ error: false, msg: "" });
    }, 2000);

    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      // campaignKey: prizeWinners && prizeWinners[0] ? prizeWinners[0].campaignKey : '',
      sessionKey: matchedParticipant ? matchedParticipant.sessionKey : "",
      email: matchedParticipant ? matchedParticipant.email : "",
      firstName:
        matchedParticipant && matchedParticipant.firstName ? matchedParticipant.firstName : "",
      lastName: (matchedParticipant && matchedParticipant.lastName) || "",
      //add more field initial values
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(
          /^[a-zA-ZÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ \-']{2,64}$/i,
          "Please enter a valid first name."
        )
        .required("Please enter a first name."),
      lastName: Yup.string()
        .matches(
          /^[a-zA-ZÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ \-']{2,64}$/i,
          "Please enter a valid last name."
        )
        .required("Please enter a last name."),
      email: Yup.string()
        .email("Please enter a valid email address.")
        .required("Please enter an email address."),
      //add more field validations
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSubmit(true);

      const params = {
        campaignKey: prizeWinners![0].campaignKey,
        sessionKey: matchedParticipant!.sessionKey,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
      };

      try {
        const res = await campaignClient.call("addParticipantData", params);

        setIsSubmit(false);
      } catch (e) {
        setIsSubmit(false);
      }
    },
  });

  const handleClose = () => {
    setShowModal(false);
    setemailparams("");
  };

  if (isLoadingParticipant || isLoadingWinners || isLoadingPrizes || !winners || isLoadingPools) {
    return <PageLoader />;
  }
  async function handleRegister() {
    setIsSubmit(true);

    const params = {
      sessionKey: matchedParticipant!.sessionKey,
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      email: formik.values.email,
      //add more field
      // postal: formik.values.postal
    };

    try {
      await campaignClient.call("addParticipantData", params);
      setIsSubmit(false);
      setMessage({ ...message, msg: "Successfull updated." });
      setTimeout(() => {
        setMessage({ error: false, msg: "" });
      }, 2000);
    } catch (e) {
      setIsSubmit(false);
    }
  }
  const columns = TableColumns();

  return (
    <>
      <div className="main__head">
        <h2 className="main__title">Participant details</h2>

        <div className="main__actions">
          <a
            href="#"
            className="btn-back"
            onClick={(e) => {
              e.preventDefault();
              navigate("/participants");
            }}>
            <svg className="ico-arrow">
              <image xlinkHref="/assets/images/svg/ico-arrow.svg"></image>
            </svg>
          </a>
        </div>
      </div>
      <div className="main__body main__body--flex">
        <div className="main__form form">
          <form action="">
            <div className="form__body">
              <h4 className="form__title">Info</h4>

              <div className="form__row">
                <label htmlFor="firstName" className="form__label">
                  First name
                </label>

                <div className="form__controls">
                  <input
                    type="text"
                    className="field"
                    id="firstName"
                    {...formik.getFieldProps("firstName")}
                  />
                </div>
                {formik.touched.firstName && formik.errors.firstName && (
                  <p className="error-message">
                    <i className="fas fa-exclamation-circle" /> {formik.errors.firstName}
                  </p>
                )}
              </div>

              <div className="form__row">
                <label htmlFor="lastName" className="form__label">
                  Last name
                </label>

                <div className="form__controls">
                  <input
                    type="text"
                    className="field"
                    id="lastName"
                    {...formik.getFieldProps("lastName")}
                  />
                </div>
                {formik.touched.lastName && formik.errors.lastName && (
                  <p className="error-message">
                    <i className="fas fa-exclamation-circle" /> {formik.errors.lastName}
                  </p>
                )}
              </div>

              <div className="form__row">
                <label htmlFor="email" className="form__label">
                  Email
                </label>

                <div className="form__controls">
                  <input
                    type="text"
                    className="field"
                    {...formik.getFieldProps("email")}
                    id="email"
                  />
                </div>
                {formik.touched.email && formik.errors.email && (
                  <p className="error-message">
                    <i className="fas fa-exclamation-circle" /> {formik.errors.email}
                  </p>
                )}
              </div>

              {/* Add more field if there more */}

              {/* <div className="form__row">
                <label htmlFor="title" className="form__label">
                  Postcal Code
                </label>

                <div className="form__controls">
                  <input
                    type="text"
                    className="field"
                    {...formik.getFieldProps("postal")}
                    id="postal"
                  />
                </div>
                {formik.touched.postal && formik.errors.postal && (
                  <p className="error-message">
                    <i className="fas fa-exclamation-circle" />{" "}
                    {formik.errors.postal}
                  </p>
                )}
              </div> */}
            </div>
            <div className="form__actions">
              {isSubmit ? (
                <button
                  type="button"
                  className="form__btn btn btn--secondary btn--secondary-alt"
                  disabled>
                  <Spinner as="span" animation="border" role="status" aria-hidden="true" /> Update
                </button>
              ) : (
                <button
                  type="button"
                  className="form__btn btn btn--secondary btn--secondary-alt"
                  onClick={handleRegister}
                  disabled={profile.toLowerCase() === "standard" ? true : false}>
                  Update
                </button>
              )}
            </div>
          </form>
        </div>
        <div className="main__content">
          <div className="table table--alt table--prizing">
            <h4 className="table__title">Prizing</h4>
            <table>
              <colgroup>
                <col className="table__col-1" style={{ width: "17.3%" }} />

                <col className="table__col-2" style={{ width: "27.7%" }} />

                <col className="table__col-3" style={{ width: "33.7%" }} />

                <col className="table__col-4" style={{ width: "auto" }} />

                {/* <col className="table__col-5" style={{ width: "auto" }} /> */}
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <span>Date / Time</span>
                  </th>

                  <th>
                    <span>Prize pool</span>
                  </th>

                  <th>
                    <span>Prize name</span>
                  </th>

                  <th>
                    <span>Status</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {winners.map((v) => (
                  <tr key={v.prizeWinnerKey}>
                    <td>{v.winningTime}</td>
                    <td>{v.poolName}</td>
                    <td>{v.prize}</td>
                    <td>{v.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>&nbsp;</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Resend Email</h3>
          <p>Are you sure you want to resend this email?</p>
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1"> Recipient email</label>
            <input
              type="text"
              className="form-control"
              style={{ width: "100%" }}
              name="email"
              value={(matchedParticipant && matchedParticipant!.email) || ""}
              disabled
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-primary small" onClick={resendemail}>
            Resend
          </button>
          <button type="button" className="btn btn-secondary small outline" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <div className="alerts">
        <div
          className={`${message.msg ? "alert alert--wide is-shown" : "alert alert--wide "} `}
          id="alert-email">
          <img className="alert__icon" src="/assets/images/svg/ico-check-circle.svg" alt="" />

          <span>{message.msg}</span>

          <a href="#" className="alert__close js-alert-close">
            <svg className="ico-cross">
              <image xlinkHref="/assets/images/svg/ico-cross.svg" />
            </svg>
          </a>
        </div>
      </div>
    </>
  );
}

export default Participant;
