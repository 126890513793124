
import { useEffect, useMemo, useState } from "react";
import { publicCampaignClient } from "../../api";
import axios from "axios";
import { useParams } from "react-router-dom";
import * as XLSX from 'xlsx';
import { PrizeWinner } from "@sprycore/spry-api-client/dist/MainDbReturnTypes";
import { PageLoader } from "../../Components";

type PrizeWinnerType = PrizeWinner & {
  partNumber: string, 
  projectNumber: string
}

type ShippingDownload = {
  winners: PrizeWinnerType[], 
  shipping: {keyWord: string}
}

export function Download() {

  const [loading, setIsLoading] = useState(true);
  const [shippingInfo, setShippingInfo]= useState<ShippingDownload | null>(null);

  const params = useParams()

  useEffect(()=>{
    const getFile = async ()=>{
      const res = await publicCampaignClient.call<{fileUrl: string}>("downloadUrl", {tk: params.key})   
      if (res.fileUrl){
        const result:{data: ShippingDownload} = await axios(res.fileUrl);
        
        setShippingInfo(result.data)
      }else{
        setIsLoading(false)
      }
    }

    getFile()
   
  }, [])

  const data = useMemo(()=>{
    if (shippingInfo){
      const shippingData =  shippingInfo.winners.map((winner)=>{
        return {
          "Cust_no": "",
          "Ship_toCode": "",
          "Attention_To": "",
          "Ship_CompanyName": `${winner.declarationAndRelease?.firstName} ${winner.declarationAndRelease?.lastName}`,
          "Ship_address": winner.declarationAndRelease?.address2 ? `${winner.declarationAndRelease.address2},${winner.declarationAndRelease.address}` : `${winner.declarationAndRelease?.address}`,
          "Ship_city": `${winner.declarationAndRelease?.city}`,
          "Ship_postalCode": `${winner.declarationAndRelease?.postal}`,
          "Ship_province": `${winner.declarationAndRelease?.province}`,
          "country": `${winner.declarationAndRelease?.country || 'CA'}`,
          "line type": "",
          "SalesType": "",
          "Cust_Order_Number": "",
          "Date_Wanted": "",
          "first_etd": "",
          "first_eta": "",
          "Pcs": "",
          "Weight": "",
          "WHS": "",
          "Part_no": `${winner.partNumber}`,
          "Project_code": `${winner.projectNumber}`,
          "budget code": "",
          "Qty": "1",
          "SO Type": "",
          "Use CSV": "",
          "email-address": `${winner.declarationAndRelease?.email}`,
          "shippingmethod": "",
          "external_notes": `${shippingInfo.shipping.keyWord}`,

        }
      })

      setIsLoading(false);

      return shippingData
    }    
    
  }, [shippingInfo])

  if (loading){
    return <PageLoader />
  }



    return (
      <div className='from-access d-flex justify-content-center vh-50'>
  
      {data && data.length > 0 ? <button type="button"
       className="btn btn-primary small"
       onClick={async () => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Shipping Info");
        XLSX.writeFile(workbook, `${shippingInfo?.shipping.keyWord}.xlsx`, { compression: true });
        
       }}
       >Download</button>:
       <h2 className="text-center">The Link you followed may be broken.</h2>
      }
  
    </div>
    )
  }
  
