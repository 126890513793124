import { useEffect } from "react"
import "./Toast.css"

export interface ToastProps {
  id: string
  destroy: () => void
  content: string
  duration?: number
  error: boolean
}

export default function Toast({ destroy, duration, error, content }: ToastProps) {
  useEffect(() => {
    if (!duration) { return }
    const timer = setTimeout(() => {
      destroy()
    }, duration)
    return () => clearTimeout(timer)
  }, [destroy, duration])

  return (
    <div className={error ? "toast-header-error" : "toast-header"}>
      <i className={error ? "fas fa-times-circle" : "fas fa-check-circle"} aria-hidden="true"      ></i>
      <p className="toast-content">{content}</p>
      <button onClick={destroy} className="toast-button">
        <span>
          <img
            src="/assets/images/ico-toast-close.png"
            alt="Close"
            className="toast-close" />
        </span>
      </button>
    </div>
  )
}