

import FooterCopyright from "./FooterCopyright";

function MenuFooter() {

  return <div className="copyright-alt">
  <FooterCopyright />
</div>;

}

export default MenuFooter;
